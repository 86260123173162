import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { getRemoveMembers } from '../../../../helpers/getterFunctions';

function RemoveMemberFailed(props) {
    const { org } = props;
    const [removeMemberFailedList, setRemoveMemberFailedList] = useState([]);
    useEffect(() => {
        getRemoveMembersFailedList();
    }, []);

    const getRemoveMembersFailedList = async (org) => {
        try {
            console.log(org);
            const response = (await getRemoveMembers())?.disapprovedRequests
            console.log(response);
            setRemoveMemberFailedList(response);
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div>
            {removeMemberFailedList.length > 0 &&
                removeMemberFailedList.map((pro, index) => {
                    return (
                        <div className='welcome-card rounded-lg p-6 mb-6 text-black' key={pro.reqId}>
                            <div className='flex flex-wrap gap-6 justify-between'>
                                <div>
                                    Name: {pro.member_name}
                                </div>
                                <div>
                                    Wallet Address: {pro.member}
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        org: state.org,
    };
};

export default connect(mapStateToProps)(RemoveMemberFailed);