import React, { useEffect, useState } from "react";
import { Layout, Menu, Drawer } from "antd";
import Accountmodal from "../../components/Accountmodal";
import { useLocation, useNavigate } from "react-router-dom";
import { getMultiSigWallet } from "../../helpers/getterFunctions";

const { Header } = Layout;

// import Logo from '../images/eqn-logo.png';

function MainHeader() {
    const [current, setCurrent] = useState("home");
    const [authorisation, setAuthorisation] = useState(false);

    const onClick = (e) => {
        console.log("click ", e);
        setCurrent(e.key);
        const { target } = items.find((item) => item.key === e.key) || {};
        console.log(target, "-----t-----");
        if (target) {
            navigate(target);
        }
    };

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const handleClick = ({ key }) => {
        document.body.classList.toggle("sidebar-open");
    };

    const connectedUser = localStorage.getItem("eqx_connected_account");
  
    let selected_chain = localStorage.getItem("selected_chain");
    let multisig_address = localStorage.getItem(
        connectedUser + "-" + selected_chain
    );

    useEffect(() => {
        const fetch = async () => {
            let value = getMultiSigWallet();
            console.log("value of multisig wallet is---->", getMultiSigWallet());
        };
        fetch();
    }, []);

    useEffect(() => {
        if (
            multisig_address !== "" &&
            multisig_address != null &&
            connectedUser !== null &&
            connectedUser !== ""
        ) {
            setAuthorisation(true);
        }
    }, [connectedUser]);

    const items = [
        {
            label: "Home",
            key: "home",
            target: "/",
            loggedIn: true,
        },
        {
            label: "Dashboard",
            key: "dashboard",
            target: "/dashboard",
            loggedIn: authorisation,
        },
        // {
        //     label: 'Projects',
        //     key: 'projects',
        // },
        // {
        //     label: 'Subscribe',
        //     key: 'subscribe',
        // },
        // {
        //     label: 'Main website',
        //     key: 'main-website',
        // },
    ];

    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Header>
                <div className="flex justify-between">
                    <div className="flex flex-auto">
                        <div className="flex-none logo self-center mr-16 flex gap-3">
                            {pathname.includes("dashboard") && (
                                <div className="flex-auto self-center">
                                    <svg
                                        onClick={handleClick}
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="hidden mv-block self-center icon icon-tabler icon-tabler-align-left"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M4 6l16 0"></path>
                                        <path d="M4 12l10 0"></path>
                                        <path d="M4 18l14 0"></path>
                                    </svg>
                                </div>
                            )}

                            {!pathname.includes("dashboard") && (
                                <div className="flex-auto self-center">
                                    <svg
                                        onClick={showDrawer}
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="hidden mv-block self-center icon icon-tabler icon-tabler-align-left"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M4 6l16 0"></path>
                                        <path d="M4 12l10 0"></path>
                                        <path d="M4 18l14 0"></path>
                                    </svg>
                                </div>
                            )}

                         <img src={require("../../images/usafe-logo.png")} alt="" className='-mt-4' />
                        </div>
                        {!pathname.includes("dashboard") && (
                            <div className="flex-auto max-md:hidden">
                                <Menu
                                    selectedKeys={[current]}
                                    onClick={onClick}
                                    mode="horizontal"
                                    items={items?.filter((item) => item.loggedIn)}
                                />
                            </div>
                        )}

                        <Drawer
                            title="MENUS"
                            placement="left"
                            onClose={onClose}
                            open={open}
                        >
                            <Menu
                                selectedKeys={[current]}
                                onClick={onClick}
                                mode="vertical"
                                items={items?.filter((item) => !item.loggedIn || connectedUser)}
                            />
                        </Drawer>
                    </div>
                    <div className='flex gap-3'>
                        <div className="self-center">
                            {/* <Button type="primary" className='grad-btn font-bold'>Off Chain</Button> */}
                            {connectedUser}
                        </div>
                        <div className="self-center">
                            {/* <Button type="primary" className='grad-btn font-bold'>Off Chain</Button> */}
                            <Accountmodal />
                        </div>
                    </div>
                </div>
            </Header>
        </div>
    );
}

export default MainHeader;
