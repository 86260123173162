import { getWeb3 } from "./currentWalletHelper"
import { getAddress } from "./addressHelper";
import contracts from "../Config/contracts";
import { getCrowdsaleContract, getEquivalentToken, getMultSigAbi, getMultiSigWallet, getTokenContract } from './getterFunctions'
import BigNumber from 'bignumber.js';
import { getContractInstance } from "../components/Accountmodal";

export const buyToken = async (tokenAmount, referrer, tokenName, account) => {
    let web3 = await getWeb3()
    let contract = await getCrowdsaleContract();
    let value = 0;
    // console.log("token type is --------->", contracts[tokenName].type);

    // big Number
    if (contracts[tokenName].type == 2) {
        value = await getEquivalentToken(tokenAmount, tokenName);
    }
    value = new BigNumber(value).multipliedBy(new BigNumber(10).exponentiatedBy(new BigNumber(18)));
    console.log("value--------->", referrer, tokenAmount, contracts[tokenName].type)
    tokenAmount = await web3.utils.toWei(tokenAmount.toString())
    let res = await contract.methods.buyTokens(referrer, tokenAmount, contracts[tokenName].type).send({ from: account, value: value })
    console.log("token buy--------->", res)
    return res;
}

export const approveTokens = async (tokenName, account) => {
    let token = await getTokenContract(tokenName);
    let crowdsaleAddress = await getAddress(contracts.crowdsale);
    let tokenAmount = "10000000000000000000000000000000000000000";
    let res = await token.methods.approve(crowdsaleAddress, tokenAmount).send({ from: account })
    return res;
}

export const setNewPercent = async (type, percent, account) => {
    try {
        let contract = await getContractInstance(getMultiSigWallet(), getMultSigAbi())
        let gas = await contract.methods.CreateRequiredPercentProposal(type, percent).estimateGas({ from: account, value: 0 })
        let web3 = await getWeb3();
        let gasPrice = await web3.eth.getGasPrice();
        let res = contract.methods.CreateRequiredPercentProposal(type, percent).send({ from: account, value: 0, gas, gasPrice })
            .on("error", (error) => {
                console.log(error);
                return false
            })
            .then(async (result) => {
                console.log(result);
                return result
            });

        res.then((result) => {
            console.log("Final Result:", result);
            return res
        });

    }
    catch (Err) {
        console.log("Err", Err)
        return false
    }
}

export const getPercentChangeProposal = async () => {
    try {
        let contract = await getContractInstance(getMultiSigWallet(), getMultSigAbi())
        let list = await contract.methods.listChangePercentProposal().call()
        console.log("list ", list)
        return list
    }
    catch (Err) {
        console.log("Err", Err)
    }
}


export const getSignedPercentChangeProposal = async (proposalId, account) => {
    try {
        let contract = await getContractInstance(getMultiSigWallet(), getMultSigAbi())
        let list = await contract.methods.changeRequiredPercentSigned(proposalId, account).call()
        console.log("account and sign ", account, list)
        return list
    }
    catch (Err) {
        console.log("Err", Err)
    }
}

export const disapproveChangePercent = async (proposalId, account) => {
    try {
        let contract = await getContractInstance(getMultiSigWallet(), getMultSigAbi())
        let gas = await contract.methods.disapproveChangePercentProposal(proposalId).estimateGas({ from: account, value: 0 })
        let web3 = await getWeb3();
        let gasPrice = await web3.eth.getGasPrice();

        let res = contract.methods.disapproveChangePercentProposal(proposalId).send({ from: account, gas, gasPrice }).on("error", (error) => {
            console.log(error);
            return false
        })
            .then(async (result) => {
                console.log(result);
                return result
            });

        console.log("res ", res)
        return res
    }
    catch (Err) {
        console.log("Err", Err)
        return false
    }
}

export const approveChangePercent = async (proposalId, account) => {
    try {
        let contract = await getContractInstance(getMultiSigWallet(), getMultSigAbi())
        let gas = await contract.methods.signChangePercentProposal(proposalId).estimateGas({ from: account, value: 0 })
        let web3 = await getWeb3();
        let gasPrice = await web3.eth.getGasPrice();
        let res = await contract.methods.signChangePercentProposal(proposalId).send({ from: account, gas, gasPrice }).on("error", (error) => {
            console.log(error);
            return false
        })
            .then(async (result) => {
                console.log(result);
                return result
            });
        console.log("res ", res)
        return res
    }
    catch (Err) {
        console.log("Err", Err)
        return false
    }
}

export const finalizeChangePercent = async (proposalId, account) => {
    try {
        let contract = await getContractInstance(getMultiSigWallet(), getMultSigAbi())
        let gas = await contract.methods.finalizeChangePercentProposal(proposalId).estimateGas({ from: account, value: 0 })
        let web3 = await getWeb3();
        let gasPrice = await web3.eth.getGasPrice();

        let res = contract.methods.finalizeChangePercentProposal(proposalId).send({ from: account, gas, gasPrice }).on("error", (error) => {
            console.log(error);
            return false
        })
            .then(async (result) => {
                console.log(result);
                return result
            });
        console.log("list ", res)
        return res
    }
    catch (Err) {
        console.log("Err", Err)
        return false
    }
}